html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

.content a {
  color: #0179bb;
}

* {
  box-sizing: border-box;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* create loading animation */

.loading {
  animation: loading 1s infinite linear;
}

.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #017abb4d 50%, transparent 50%);
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: absolute;
  right: -50%;
  top: 0;
  /* z-index: -1; */
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}


h1 {
  font-family: monospace;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

.content img,
.content iframe {
  max-width: 100%;
}

.content pre,
.content code {
  padding: 12px 30px;
  border-radius: 4px;
  line-height: 1;
  font-size: 14;
}

.content pre {
  overflow: auto;
}

.content code {
  background-color: #5a5d865a;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1;
  font-size: 14;
  line-break: anywhere;
  align-items: center;
}

iframe.ql-video {
  width: 100%;
  height: 320px;
}

.content a {
  text-decoration: underline;
}

.content {
  overflow-wrap: break-word;
}

.see-more {
  text-decoration: underline;
  cursor: pointer;
  color: #0179bb;
}

.ql-editor {
  min-height: 250px;
}
